<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div
        class="searchWrapper bgFFF"
        @keydown.enter.prevent="
          pageNum = 1;
          searchData;
        "
      >
        <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>

        <el-form
          :inline="true"
          label-position="right"
          ref="searchWrapper"
          :rules="searchRule"
          :model="formInline"
          class="demo-form-inline"
        >
          <div class="col_box">
            <div class="col_left">
              <!-- <el-form-item :label="$t('searchModule.Vehicle_ype')" prop="energyType">
          <el-select v-model.trim="formInline.energyType" filterable size="15">
            <el-option label="请选择车辆类型" value=""></el-option>
            <el-option label="电动车" value="1"></el-option>
            <el-option label="燃油车" value="2"></el-option>
          </el-select>
        </el-form-item> -->
              <!-- <el-form-item label="车牌类型">
               <el-form-item label="车牌类型">
					<el-select v-model="formInline.plateType">
						<el-option label="黄牌车" :value="1"/>
					</el-select>
				</el-form-item> -->
              <!-- <el-form-item label="车型分类" prop="carType">

          <el-select v-model.trim="formInline.carType" filterable size="15">
            <el-option label="小型车" value="0"></el-option>
            <el-option label="中型车" value="1"></el-option>
            <el-option label="大型车" value="2"></el-option>
          </el-select>
        </el-form-item> -->
              <el-form-item :label="$t('searchModule.plate_number')">
                <el-autocomplete
                  :maxlength="8"
                  ref="plateNumber"
                  size="11"
                  valueKey="plateNumber"
                  v-model="formInline.plateNumber"
                  :fetch-suggestions="querySearchAsync"
                  placeholder="车牌号"
                  @select="handleSelect"
                  @input="handleInitCreateDate"
                ></el-autocomplete>
              </el-form-item>
              <el-form-item label="会员手机号" label-width="90px" prop="mobile">
                <el-input
                  type="text"
                  v-model="formInline.mobile"
                  maxlength="11"
                  placeholder="请输入手机号"
                ></el-input>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Authentication_status')">
                <el-select v-model="formInline.authStatus">
                  <el-option
                    v-for="item in authList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Binding_status')">
                <el-select v-model="formInline.bindStatus">
                  <el-option
                    v-for="item in bindList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
              <!-- <el-form-item :label="$t('searchModule.arrears')">
              <el-form-item :label="$t('searchModule.plate_number')">
                <el-autocomplete
                  :maxlength="8"
                  ref="plateNumber"
                  size="11"
                  valueKey="plateNumber"
                  v-model="formInline.plateNumber"
                  :fetch-suggestions="querySearchAsync"
                  placeholder="车牌号"
                  @select="handleSelect"
                  @input="handleInitCreateDate"
                ></el-autocomplete>
              </el-form-item>
              <el-form-item label="会员手机号" label-width="90px" prop="mobile">
                <el-input
                  type="text"
                  v-model="formInline.mobile"
                  maxlength="11"
                  placeholder="请输入手机号"
                ></el-input>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Authentication_status')">
                <el-select v-model="formInline.authStatus">
                  <el-option
                    v-for="item in authList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Binding_status')">
                <el-select v-model="formInline.bindStatus">
                  <el-option
                    v-for="item in bindList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.arrears')">
					<el-select v-model="formInline.amountOwed">
            <el-option v-for="item in amountOwedList" :key="item.value" :label="item.label" :value="item.value"/>
					</el-select>
				</el-form-item> -->
              <el-form-item :label="$t('searchModule.Creation_time')">
                <el-date-picker
                  v-model="createdDate"
                  type="datetimerange"
                  :clearable="false"
                  range-separator="至"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  :default-time="['00:00:00', '23:59:59']"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                >
                </el-date-picker>
                <i class="el-icon-date el-icon-date-custom"></i>
              </el-form-item>
              <!-- <el-form-item>
          <el-input v-model="formInline.text">
            <div slot="prepend">
              <el-select v-model="formInline.textType" style="width: 80px">
                <el-option :label="$t('searchModule.full_name')" :value="1"/>
                <el-option label="车牌" :value="2"/>
                <el-option label="手机" :value="3"/>
              </el-select>
            </div>
            <div slot="append">
              <el-button icon="el-icon-search" :loading="loading" @click="pageNum=1;searchData()"/>
            </div>
          </el-input>
        </el-form-item> -->
            </div>
            <div class="col_right">
              <AuthorityComponent
                ComponentName="el-button"
                :permission="['button.query']"
                type="primary"
                :loading="loading"
                @click="handleSearch"
                icon="el-icon-search"
                >查询
              </AuthorityComponent>
              <el-button icon="el-icon-delete" @click="resetForm()"
                >清空</el-button
              >
            </div>
          </div>
          <div class="col_box_boder"></div>
          <div class="col_box h44">
            <div class="col_left"></div>
            <div class="col_right mbd4">
              <el-button icon="el-icon-upload2" @click="exportFile"
                >导出
              </el-button>
            </div>
          </div>
        </el-form>
      </div>
      <!-- 弹窗 -->
      <!-- <pic-detail :infoSize="4" @close="dialogVisible=false" :infoDetail="parkDetail" :tableOrder='tableOrder' :rowData='rowData' :detailFlag="dialogVisible"></pic-detail> -->
      <!--列表区域-->
      <div class="tableWrapper bgFFF paddingB10">
        <el-table
          border
          v-loading="loading"
          :data="tableData"
          style="width: 100%"
        >
          <el-table-column
            type="index"
            :label="$t('list.index')"
            width="70"
            align="center"
          ></el-table-column>
          <el-table-column
            :prop="item.prop"
            align="center"
            :label="item.label"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
            show-overflow-tooltip
          ></el-table-column>
          <AuthorityComponent
            ComponentName="el-table-column"
            :permission="['button.view']"
            align="center"
            label="操作"
            width="140"
          >
            <template slot-scope="scope">
              <el-button type="text" @click="toDetail(scope.row)"
                >查看</el-button
              >
            </template>
          </AuthorityComponent>
        </el-table>
        <!--分页器-->
        <div class="pagerWrapper">
          <div class="block">
            <el-pagination
              v-if="total != 0"
              @current-change="handleCurrentChange"
              :current-page="pageNum"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { setIndex, exportExcelNew } from "../../common/js/public.js";
import Reg from "@/common/js/RegExp";
import { getZeroOrLastDateTime } from "@/common/js/utils";
import qs from "qs";
// import picDetail from './picDetail'
export default {
  name: "carManager",
  data() {
    let checkParkNum = (rule, value, callback) => {
      let len = value.length;
      if (!value) {
        callback();
      } else if (len < 3) {
        callback(new Error("至少输入三个字符"));
      } else if (len > 3 && len <= 11) {
        callback();
      } else if (len > 20) {
        callback(new Error("最多输十一个字符"));
      }
    };
    let start = getZeroOrLastDateTime("yyyy-MM-dd HH:mm:ss", -90, true);
    let end = getZeroOrLastDateTime("yyyy-MM-dd HH:mm:ss", 0, true);
    return {
      dialogVisible: false,
      parkDetail: [],
      rowData: [],
      total: 0,
      pageSize: 15,
      pageNum: 1,
      loading: false,
      searchRule: {
        plateNumber: [
          {
            validator: checkParkNum,
            trigger: "blur",
          },
        ],
      },
      carColorList: [],
      tableCols: [
        // {
        //   prop: 'carTypeDesc',
        //   label: '车型分类'
        // },
        {
          prop: "plateNumber",
          label: this.$t("list.plate_number"),
        },
        {
          prop: "memberName",
          label: "会员姓名",
        },
        {
          prop: "mobile",
          label: "会员手机号",
        },
        {
          prop: "authStatus",
          label: "行驶本认证",
          formatter: (row) => {
            switch (row.authStatus) {
              case 0:
                return "未认证";
              case 1:
                return "已认证";
              default:
                return "未认证";
            }
          },
        },
        {
          prop: "debtMoney",
          label: this.$t("list.arrears"),
          formatter: (row) => {
            if (row.debtMoney) {
              return Number(row.debtMoney / 100).toFixed(2);
            } else {
              return "0.00";
            }
          },
        },
        {
          prop: "strCreatedTime",
          label: this.$t("list.Creation_time"),
        },
        // {
        //   prop: 'carType',
        //   label: '车型分类',
        //   width: '',
        //   formatter: function(row, column, cellValue, index) {
        //     switch (cellValue) {
        //       case 0:
        //         return '小型车'
        //       case 1:
        //         return '中型车'
        //       case 2:
        //         return '大型车'
        //       default: '-'
        //         break;
        //     }
        //   }
        // },
        // {
        //   prop: 'energyType',
        //   label: '车辆类型',
        //   width: '',
        //   formatter: function(row, column, cellValue, index) {
        //     switch (cellValue) {
        //       case 1:
        //         return '电动车'
        //       case 2:
        //         return '燃油车'
        //       default: '-'
        //         break;
        //     }
        //   }
        // }
      ],
      tableData: [],
      formInline: {
        plateNumber: "",
        authStatus: "",
        mobile: "", // 会员手机号
        bindStatus: "", // 绑定状态
        // amountOwed: "", // 欠费金额
      },
      createdDate: [start, end], // 创建时间
      authList: [
        {
          label: "请选择",
          value: "",
        },
        {
          label: "未认证",
          value: 0,
        },
        {
          label: "已认证",
          value: 1,
        },
      ],
      bindList: [
        {
          label: "请选择",
          value: "",
        },
        {
          label: "未绑定",
          value: 0,
        },
        {
          label: "已绑定",
          value: 1,
        },
      ],
      amountOwedList: [
        {
          label: "全部",
          value: "",
        },
        {
          label: "0-50元",
          value: 0,
        },
        {
          label: "50-100元",
          value: 1,
        },
        {
          label: "100-200元",
          value: 2,
        },
        {
          label: "200元以上",
          value: 3,
        },
      ],
      startDate: start,
      endDate: end,
    };
  },
  methods: {
    // 导出
    exportFile() {
      // exportExcelNew(
      //   "/acb/2.0/car/query/export",
      //   {
      //     page: this.pageNum,
      //     pageSize: this.pageSize,
      //     ...this.formInline,
      //     startCreatedTime: this.createdDate[0],
      //     endCreatedTime: this.createdDate[1],
      //   },
      //   "post",
      //   "",
      //   {
      //     headers: {
      //       "Content-Type": "application/json;charset=UTF-8",
      //     },
      //   }
      // );
      const params = {
        page: this.pageNum,
        pageSize: this.pageSize,
        ...this.formInline,
        startCreatedTime: this.createdDate[0],
        endCreatedTime: this.createdDate[1],
      };
      const qsParams = qs.stringify(params, {
        arrayFormat: "repeat",
      });

      this.$axios
        .post(
          `/acb/2.0/car/query/export?${qsParams}`,
          {},
          {
            responseType: "blob",
            headers: {
              "Content-Type": "application/json;charset=UTF-8",
            },
          }
        )
        .then((res) => {
          // if (hint.length > 0) {
          //   MessageBox.alert(hint, this.$t('pop_up.Tips'), {
          //     confirmButtonText: this.$t('pop_up.Determine')
          //   });
          // }
        });
    },
    // 清空
    resetForm() {
      this.formInline = {
        plateNumber: "",
        authStatus: "",
        mobile: "", // 会员手机号
        bindStatus: "", // 绑定状态
        // amountOwed: "", // 欠费金额
        carId: "",
      };
      let start = getZeroOrLastDateTime("yyyy-MM-dd HH:mm:ss", -90, true);
      let end = getZeroOrLastDateTime("yyyy-MM-dd HH:mm:ss", 0, true);
      this.createdDate = [start, end];
    },
    handleSearch() {
      const { mobile } = this.formInline;
      if (mobile && !Reg.phoneReg.test(mobile)) {
        return this.$alert("请输入正确的手机号", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
      }
      this.pageNum = 1;
      this.searchData();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.searchData();
    },
    // 车牌号
    querySearchAsync(queryString, cb) {
      if (queryString.length < 3) {
        cb([]);
        return;
      }
      let opt = {
        method: "get",
        url: "/acb/2.0/parkRecord/plateNumber/list",
        data: {
          part: queryString,
          size: 100,
        },
        success: (res) => {
          this.loading = false;
          if (res.state == 0) {
            res.value.forEach((v) => {
              v.value = v.plateNumber;
            });
            cb(res.value);
          }
        },
      };
      this.$request(opt);
    },
    handleSelect(item) {
      this.formInline.plateNumber = item.plateNumber;
      this.formInline.carId = item.carId;
      this.createdDate = [];

      this.pageNum = 1;
      this.searchData();
    },
    handleInitCreateDate(value) {
      if (!value) {
        this.createdDate = [this.startDate, this.endDate];
      }
    },
    // 搜索
    searchData() {
      if (this.formInline.plateNumber.length < 3) {
        this.formInline.plateNumber = "";
        this.formInline.carId = "";
      }
      if (
        !this.createdDate ||
        this.createdDate[0] == "" ||
        this.createdDate[1] == ""
      ) {
        this.$alert("请选择创建时间", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return;
      }
      const startTime = new Date(this.createdDate[0]).getTime(),
        endTime = new Date(this.createdDate[1]).getTime();
      if (endTime - startTime > 3600 * 1000 * 24 * 90) {
        this.$alert("创建时间范围为90天内", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return;
      }
      this.loading = true;
      const opt = {
        method: "post",
        url: "/acb/2.0/car/query",
        data: {
          page: this.pageNum,
          pageSize: this.pageSize,
          authStatus: this.formInline.authStatus,
          carId: this.formInline.carId,
          mobile: this.formInline.mobile,
          bindStatus: this.formInline.bindStatus,
          startCreatedTime: this.createdDate[0],
          endCreatedTime: this.createdDate[1],
        },
        success: (res) => {
          this.loading = false;
          this.tableData =
            (res.value && setIndex(this.pageNum, res.value.list)) || [];
          this.total = res.value.total * 1 || 0;
        },
        fail: (err) => {
          this.loading = false;
        },
      };
      this.$request(opt);
    },
    getColor() {
      this.$axios.get("/acb/2.0/specialplate/plateColor/list").then((res) => {
        if (res.state == 0) {
          this.carColorList = res.value;
        } else {
          this.$alert(res.desc, "提示");
        }
      });
    },
    // 进入详情页
    toDetail(row) {
      let colorText = "";

      for (let i in this.carColorList) {
        if (this.carColorList[i].code == row.plateColor) {
          colorText = this.carColorList[i].desc;
        }
      }
      this.$router.push({
        path: "/VehicleininfoDetail",
        query: {
          carId: row.carId,
          memberId: row.memberId,
          isAuth: row.authStatus === 1 ? "true" : "false",
          plateColor: row.plateColor,
          plateNumber: `${colorText}:${row.plateNumber}`,
          strCreatedTime: row.strCreatedTime,
          mobile: row.mobile,
          authStatusDesc: row.authStatusDesc,
        },
      });
    },
  },

  components: {
    // picDetail
  },
  activated() {
    this.searchData();
  },
  mounted() {
    // this.searchData();
    this.getColor();
    this.getColor();
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.breadcrumb {
  height: 35px;
}

.content {
  overflow: hidden;

  .chartsWrapper {
    height: 360px;
    margin-bottom: 20px;

    .wrapper {
      background: #FFFFFF;
      border: 1px solid #C0CCDA;
      border-radius: 4px;

      .title {
        font-size: 14px;
        color: #475669;
        padding-left: 17px;
        height: 50px;
        line-height: 50px;
      }
    }
  }

  .pagerWrapper {
    text-align: right;
    margin-top: 28px;
    font-size: 12px;

    .export {
      font-size: 12px;

      .iconfont {
        margin-right: 0;
      }
    }
  }

  >>>.el-input-group__append {
    padding: 0;
  }
}
</style>
